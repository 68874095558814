import axiosIns from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchInvoices(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get('/business/transactions/fuels', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchInvoice(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get('/business/transactions/fuels')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        // addUser(ctx, userData) {
        //   return new Promise((resolve, reject) => {
        //     axiosIns
        //       .post('/apps/user/users', { user: userData })
        //       .then(response => resolve(response))
        //       .catch(error => reject(error))
        //   })
        // },
    },
}